import React from 'react';
import { IForm } from '../../../models/list-forms-model';
import { Checkbox } from '../../uikit/Checkbox/Checkbox';
import { SelectField } from '../../uikit/SelectField/SelectField';
import { TextField } from '../../uikit/TextField/TextField';
import { FormField } from '../CardForms/CardFormSimple';


type Props = {
  field: FormField;
};

export const FieldReadonly = ({field }: Props) => {
  const getValueText = () => {
    switch (field.type) {
      case 'Boolean': {
        return (
          <Checkbox
            checked={field.value === 'true'}
          />
        );
      }
      case 'Enum':
      case 'Dictionary': {
        const valueText =
          field.options?.find((option) => option.value === field.value)?.name ||
          field.value;
        return valueText;
      }
      default:
        return field.value;
    }
  };

  return <>{getValueText()}</>
};

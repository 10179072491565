import React, { useContext, useEffect, useState } from 'react';
import { produce } from 'immer';
import { FormsList, IForm } from '../../../models/list-forms-model';
import { MetadataContext } from '../../../propviders/MetadataPropvider';
import {
  apiCreateDictRecord,
  apiDeleteDictRecord,
  apiGetDict,
  apiGetRecord,
  apiUpdateDictRecord,
} from '../../../servises/dict';
import { FieldType, FieldValueType, FormDataItem } from '../../../types/common/form';
import { Button } from '../../uikit/Button/Button';
import { Icon } from '../../uikit/Icon/Icon';
import ModalDelete from '../../uikit/Modals/ModalDelete';
import { SelectField } from '../../uikit/SelectField/SelectField';
import {
  CardFormSimple,
  FormField,
  FormListView,
} from '../CardForms/CardFormSimple';
import { ReadOnlyFields } from '../helper';
import { useFormSchemaAndData } from '../hooks';

import styles from './CardFormController.module.css';
import { useNavigate } from 'react-router-dom';
import { ButtonIcon } from '../../uikit/ButtonIcon/ButtonIcon';

type CardFormControllerProps = {
  form: IForm;
  id?: number | undefined;
};

export const CardFormController = ({ form, id }: CardFormControllerProps) => {
  const navigate = useNavigate();

  const isNew = id === undefined;

  const { metaFields, metaEnums } = useContext(MetadataContext);

  const [isEditedState, setIsEdited] = useState(false);
  const isEdited = isEditedState && !isNew;
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);


  const { formData, isLoading } = useFormSchemaAndData({
    form,
  });
  const [cardFormFields, setCardFormFields] = useState<FormField[]>([]);
  const [cardListViews, setCardListViews] = useState<FormListView[]>([]);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  useEffect(() => {
    const getCardFields = async () => {
      return Promise.all(
        formData.fieldsForCardForm
          .filter((field) =>
            isNew ? !ReadOnlyFields.includes(field.code) : true
          )
          .map(async ({ code, type }) => {
            let options = undefined;

            if (type === 'Enum') {
              options = metaEnums[metaFields[code]?.dict]?.valuesArray.map(
                (item) => ({
                  name: item.localName,
                  value: item.objectId,
                })
              );
            }
            if (type === 'Dictionary') {
              const dictCode = metaFields[code]?.dict;
              const form = FormsList.find((form) => form.code === dictCode);
              // options =
              const dictData = form
                ? (
                    await apiGetDict(
                      form.serviceCode,
                      form.api || dictCode,
                      form.urlParams
                    )
                  ).data
                : [];

              options = dictData.map((item) => ({
                name: String(item.fullName || ''),
                value: String(item.id || ''),
              }));
            }

            return {
              code,
              label: metaFields[code]?.localName || code,
              type,
              oldValue: type === 'String' ? '' : null,
              value: type === 'String' ? '' : null,
              options,
            };
          })
      );
    };

    getCardFields().then((cardFields) => {
      if (cardFields.length === 0) return;

      // get the one record
      if (id) {
        apiGetRecord(form.serviceCode, form.api || form.code, String(id))
          .then((respRecord) => {
            const cardFieldsFilled = cardFields.filter((field) => !['Listview', 'ListView'].includes(field.type)).map((field) => {
              const value =
                respRecord[field.code] === null
                  ? null
                  : field.type === 'Dictionary'
                    ? (respRecord[field.code] as any)?.fullName || ''
                    : String(respRecord[field.code] ?? '');

              return {
                ...field,
                oldValue: value,
                value,
              };
            });
            const listViews = cardFields.filter((field) => ['Listview', 'ListView'].includes(field.type)).map((field) => {
              const values: FormDataItem[] = respRecord[field.code] as unknown as FormDataItem[];

              return {
                code: field.code,
                label: field.label,
                values,
              };
            });
            setCardFormFields(cardFieldsFilled);
            setCardListViews(listViews);
          })
          .catch((err) => {
            console.error(
              'Ошибка получения одной записи...',
              form.serviceCode,
              form.api || form.code,
              id,
              err
            );
          });
      } else {
        setCardFormFields(cardFields);
        setCardListViews([]); // пока для новых записей не умеем создавать ListView, так как нет metadata.fields, да и вообще не понятно как...
      }
    });
  }, [
    formData.fieldsForCardForm,
    isNew,
    id,
    metaEnums,
    metaFields,
    form.serviceCode,
    form.api,
    form.code,
  ]);

  if (isLoading) return <div> Загрузка... </div>;

  const handleSave = (update: boolean = false) => {
    const record = cardFormFields.reduce<Record<string, FieldValueType>>(
      (acc, field) => {
        const value =
          ['Number', 'Dictionary'].includes(field.type) && field.type !== null
            ? parseFloat(field.value || '')
            : field.value;

        acc[field.code] = value;
        return acc;
      },
      {}
    );

    if (isNew) {
      apiCreateDictRecord(form.serviceCode, form.api || form.code, record)
        .then((resp) => {
          navigate(
            `/forms/${form.groupCode}/${form.subGroupCode}/${form.code}/${resp.id}`
          );
        })
        .catch((err) => {
          console.error(
            'Ошибка создания записи...',
            form.serviceCode,
            form.api || form.code,
            record,
            err
          );
        })
        .finally(() => {
          setIsSaveLoading(false);
        });
    } else {
      apiUpdateDictRecord(
        form.serviceCode,
        form.api || form.code,
        String(id),
        record
      )
        .then(() => {
          setIsEdited(false);
        })
        .catch((err) => {
          console.error(
            'Ошибка обновления записи...',
            form.serviceCode,
            form.api || form.code,
            record,
            err
          );
        })
        .finally(() => {
          setIsSaveLoading(false);
        });
    }
  };

  const setFieldValue = (fieldCode: string, value: string | null) => {
    setCardFormFields((oldFields) =>
      produce(oldFields, (draft) => {
        const index = oldFields.findIndex((field) => field.code === fieldCode);
        if (index >= 0) {
          draft[index].value = value;
        }
      })
    );
  };

  const resetFieldValues = () => {
    setCardFormFields((oldFields) =>
      produce(oldFields, (draft) => {
        draft.forEach((field) => (field.value = field.oldValue));
      })
    );
  };

  const deleteRecord = () => {
    setIsDeleteLoading(true);
    apiDeleteDictRecord(form.serviceCode, form.api || form.code, String(id))
      .then(() => {
        navigate(`/forms/${form.groupCode}/${form.subGroupCode}/${form.code}`);
      })
      .catch((err) => {
        console.error('Ошибка при удалении записи', err);
      })
      .finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const handleDeleteRecord = () => {
    setIsOpenDeleteModal(true);
  };

  const cardFormFieldsFiltered = cardFormFields.filter(
    (field) =>
      (isNew || field.code !== 'status') &&
      (!isEdited || field.code !== 'fullName')
  );
  const statusField = cardFormFields.find((field) => field.code === 'status');
  const statusFieldLabel = statusField?.options?.find(
    (option) => option.value === statusField?.value
  )?.name;

  return (
    <div className={styles.CardFormPage}>
      <div className={styles.Content}>
        <div className={styles.ControllerHeader}>
          <div className={styles.ControllerName}>
            {isNew
              ? 'Создать новую запись'
              : isEdited
                ? 'Редактировать запись'
                : 'Просмотр записи'}
          </div>
          {!isNew && (
            <div className={styles.ControllerPanel}>
              <div className={styles.Status}>
                <span className={styles.StatusLabel}>Статус:</span>
                {isEdited && statusField ? (
                  <SelectField
                    values={statusField.value ? [statusField.value] : []}
                    options={statusField.options || []}
                    handleChange={(values) =>
                      setFieldValue(statusField.code, values?.[0] || null)
                    }
                  />
                ) : (
                  <span>{statusFieldLabel}</span>
                )}
              </div>
              <ButtonIcon
                variant="additional"
                iconName="Delete"
                disabled={isDeleteLoading}
                onClick={handleDeleteRecord}
              />
              {!isEdited && (
                <Button
                  variant="additional"
                  iconStart={<Icon name="Edit" />}
                  onClick={() => setIsEdited(true)}
                >
                  Редактировать
                </Button>
              )}
            </div>
          )}
        </div>
        <div className={styles.Form}>
          <CardFormSimple
            form={form}
            fields={cardFormFieldsFiltered}
            setFieldValue={setFieldValue}
            isReadOnly={!isNew && !isEdited}
            listViews={cardListViews}
          />
        </div>
      </div>
      {(isEdited || isNew) && (
        <div className={styles.CardFormPageFooter}>
          <Button
            variant="additional"
            disabled={isSaveLoading}
            onClick={() => {
              setIsEdited(false);
              resetFieldValues();
            }}
          >
            Отменить
          </Button>
          {isNew ? (
            <Button onClick={() => handleSave()} disabled={isSaveLoading}>
              Создать
            </Button>
          ) : (
            <Button onClick={() => handleSave(true)} disabled={isSaveLoading}>
              Сохранить
            </Button>
          )}
        </div>
      )}
      {isOpenDeleteModal && (
        <ModalDelete
          handleDelete={deleteRecord}
          handleCancel={() => setIsOpenDeleteModal(false)}
        />
      )}
    </div>
  );
};

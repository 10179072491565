import axios from 'axios';
import { api } from '../utils/api';

export type MetadataItemType = 'String' | 'Number' | 'Boolean' | 'DateTime' | 'Enum' | 'Dictionary' | 'Listview' | 'ListView' | '';

export interface IMetadataItem {
  kind: number;
  objectId: string;
  localization: number;
  localName: string;
  type: MetadataItemType;
  service: string;
  dict: string;
}

export const fetchMetadata = async (
  signal: AbortSignal
): Promise<IMetadataItem[] | undefined> => {
  try {
    const data = (
      await api.get('/api/adm/metadata', {
        signal,
      })
    ).data.data as [];
    return data;
  } catch (err) {
    if (!axios.isCancel(err)) {
      throw err;
    }
  }
};

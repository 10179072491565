import React, { useContext, useEffect, useState } from 'react';
import { produce } from 'immer';
import { MetadataContext } from '../../../propviders/MetadataPropvider';
import { FormDataItem, ListFormData, SchemaField } from '../../../types/common/form';
import { FormListView } from '../CardForms/CardFormSimple';
import { getSchema, ServiceFields } from '../helper';
import { ListFormTable } from '../ListFormTable/ListFormTable';

const listFormHeaderHeight = 56;
const listFormHeaderBottomMargin = 10;

function transformData(data: FormDataItem[], schema: SchemaField[]): any[] {
  return data.map((item) => {
    const transformedItem: any = {};

    for (const field of schema) {
      const { code, type } = field;

      if (type === "Dictionary" && item[code] && typeof item[code] === "object") {
        // Заменяем значение поля на fullName, если это Dictionary
        transformedItem[code] = (item[code] as unknown as any)?.fullName || ''; // TODO убрать as unknown as any
      } else {
        // Сохраняем значение без изменений
        transformedItem[code] = item[code];
      }
    }

    return transformedItem;
  });
}

type Props = {
  listView: FormListView;
};

export const ListFormListView = ({ listView }: Props) => {
  const { isLoadingMetadata, metaFields } = useContext(MetadataContext);

  const listViewName = listView.label;

  const [formData, setFormData] = useState<
    ListFormData
  >({ rows: [], fields: [] });

  useEffect(() => {
    const fields = getSchema(listView.values, metaFields).filter((field) => !ServiceFields.includes(field.code));
    const rows = transformData(listView.values, fields);
    setFormData({ rows: rows, fields });
  }, [listView.values, metaFields]);

  // const { formData, setFormData, isLoading, fetchDict } = useFormSchemaAndData({ form });

  const selectedRows = formData.rows.filter(({ _selected }) => _selected);

  const selectRow = (key: number, value: boolean) => {
    setFormData((_form) =>
      produce(_form, (draft) => {
        const rowIndex = draft.rows.findIndex(({ _key }) => _key === key);
        draft.rows[rowIndex]._selected = value;
      })
    );
  };

  // Добавил проверку на всякий случай. Рассчитываю, что это условие не случится, т.е. мы на данный момент не попадаем сюда если не загружена метадата
  if (isLoadingMetadata) return <div>Загрузка метадаты...</div>;

  return (
      <div
        style={{ width: '100%', height: `calc(100% - ${listFormHeaderHeight + listFormHeaderBottomMargin}px)`, overflow: 'auto' }}
      >
        <ListFormTable
          listFormData={formData}
          selectRow={selectRow}
          // setFieldProp={setFieldProp}
          // setSortField={setSortField}
          // handleDoubleRecordClick={handleEditRecord}
        />
      </div>
  );
};

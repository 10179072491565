import React, { useEffect } from 'react';

export interface ContainerSize {
  width: number;
  height: number;
}

export const useResizeObserver = (
  elementRef: React.RefObject<HTMLElement>,
  callback: (rect: ContainerSize) => void,
) => {
  const currentElementRef = elementRef.current;

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === currentElementRef) {
          const rect = {
            width:
              entry.contentBoxSize[0].inlineSize ?? entry.contentRect.width,
            height:
              entry.contentBoxSize[0].blockSize ?? entry.contentRect.height,
          };
          callback(rect);
        }
      }
    });

    if (currentElementRef) {
      resizeObserver.observe(currentElementRef);
    }

    return () => {
      if (currentElementRef) {
        resizeObserver.unobserve(currentElementRef);
      }
    };
  }, [callback, currentElementRef]);
};

import { clsx } from 'clsx';
import React from 'react';
import { FormsList, IForm } from '../../../models/list-forms-model';
import { FieldType, FieldValueType, FormDataItem } from '../../../types/common/form';
import { ParentSize } from '../../../utils/resize-observer/adapters/ParentSize';
import { Option, SelectField } from '../../uikit/SelectField/SelectField';
import { TextField } from '../../uikit/TextField/TextField';
import { FieldEdit } from '../FormFields/FieldEdit';
import { FieldReadonly } from '../FormFields/FieldReadonly';
import { ListFormController } from '../ListFormController/ListFormController';
import { ListFormListViewTabs } from '../ListFormController/ListFormListViewTabs';
import styles from './CardForm.module.css';

export interface FormField {
  code: string;
  label: string;
  type: FieldType;
  oldValue: string | null;
  value: string | null;
  options?: Option[];
}
export interface FormListView {
  code: string;
  label: string; // возможно это поле лишнее тут, так как мы можем получить лейбл из metadata, получается повторение данных...
  values: FormDataItem[];
}

type CardFormSimpleProps = {
  form: IForm;
  fields: FormField[];
  setFieldValue: (fieldCode: string, value: string | null) => void;
  isReadOnly: boolean;
  listViews: FormListView[];
};

export const CardFormSimple = ({
  form,
  fields,
  setFieldValue,
  isReadOnly,
  listViews,
}: CardFormSimpleProps) => {
  const form2 = FormsList.find((form) => form.code === 'unit');

  return (
    <div className={styles.CardForm}>
      <div className={styles.Header}>{form.name}</div>
      <div className={styles.Form}>
        <div className={styles.Container}>
          {fields.map((field) => (
            <div key={field.code} className={styles.FieldContainer}>
              <div className={styles.LabelWrap}>
                <div className={styles.Text2Lines}>
                  {field.label}:
                </div>
              </div>
              <div className={styles.InputWrap}>
                {isReadOnly ? (
                  <div className={clsx(styles.Value, styles.Text2Lines)}>
                    <FieldReadonly field={field} />
                  </div>
                ) : (
                  <FieldEdit field={field} setFieldValue={setFieldValue} />
                )}
              </div>
            </div>
          ))}
        </div>
        <ParentSize>
          {({ height }) => {
            return (
              <div style={{ height }}>
                {/*<ListFormController form={form2!} />*/}
                <ListFormListViewTabs listViews={listViews} />
              </div>
            )
          }}
        </ParentSize>
      </div>
    </div>
  );
};

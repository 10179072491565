import React, { useRef } from 'react';
import { useContainerResizeObserver } from './useContainerResizeObserver';


export type ParentSizeState = {
  width: number;
  height: number;
}

export type ParentSizeProps = /*React.HTMLAttributes<HTMLDivElement> &*/ {
  children: (size: ParentSizeState) => React.ReactNode;
};

export const ParentSize = ({ children }: ParentSizeProps) => {

  const elementRef = useRef<HTMLDivElement>(null);

  const { width, height } = useContainerResizeObserver(elementRef);

  const size = {
    width,
    height,
  };

  return (
    <div ref={elementRef} style={{ width: '100%', height: '100%' }}>{children(size)}</div>
  )
};

import React, { useCallback, useState } from 'react';
import { ContainerSize, useResizeObserver } from './useResizeObserver';


export const useContainerResizeObserver = (
  elementRef: React.RefObject<HTMLElement>,
) => {
  const [size, setSize] = useState<ContainerSize>({ width: 0, height: 0 });

  const callback = useCallback((rect: ContainerSize) => {
    setSize(rect);
  }, []);

  useResizeObserver(elementRef, callback);

  return size;
};

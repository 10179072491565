import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import ClickOutside from '../ClickOutside/ClickOutside';
import Portal from '../Portal/Portal';


const positionInit = {
  top: 0,
  bottom: 0,
  left: 0,
  width: 10000, // если будет 0, то контент "скукожится" и из-за переноса строк в контенте в useEffect будет не корректно рассчитана высота Dropdown
  placeTop: false,
  isDropdownReady: false,
};

type DropdownProps = {
  open: boolean;
  onClose: () => void;
  dropdown: React.ReactNode;
  children: React.ReactNode;
  width?: number;
};

export const Dropdown = ({
  open,
  onClose,
  children,
  dropdown,
  width: manualWidth,
}: DropdownProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Добавляем локальное состояние для позиций
  const [position, setPosition] = useState(positionInit);

  // Не можем использовать useLayoutEffect, потому что контент рендерится в createPortal,
  // по этому приходится использовать трюк с opacity: 0, чтобы не было мерцания.
  useEffect(() => {
      if (open && anchorRef.current) {
        const { top, left, width, bottom } =
          anchorRef.current.getBoundingClientRect();
        const rectDropdown = dropdownRef.current?.getBoundingClientRect();

        setPosition({
          top,
          bottom,
          left,
          width,
          placeTop: bottom + (rectDropdown?.height || 0) >= window.innerHeight,
          isDropdownReady: (rectDropdown?.height || 0) > 0,
        });
      }
    return () => {
      setPosition(positionInit);
    }
  }, [open]);

  const stylePlace: CSSProperties = position.placeTop
    ? {
        bottom: window.innerHeight - position.top,
        maxHeight: position.top,
      }
    : {
        top: position.bottom,
        maxHeight: window.innerHeight - position.bottom,
      };

  const styleContainer: CSSProperties = {
    position: 'fixed',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    left: position.left,
    width: manualWidth || position.width,
    opacity: position.isDropdownReady ? 1 : 0,
    ...stylePlace,
  };

  const handleClose = (event: MouseEvent) => {
    if (!containerRef.current?.contains(event.target as Node)) {
      onClose();
    }
  };

  return (
    <div ref={containerRef}>
      <div ref={anchorRef}>{children}</div>
      {open && (
        <Portal>
          <ClickOutside onClickOutside={handleClose}>
            <div ref={dropdownRef} style={styleContainer}>
              {dropdown}
            </div>
          </ClickOutside>
        </Portal>
      )}
    </div>
  );
};
